import { ReactQueryDevtools } from "react-query/devtools";
import { ToastContainer } from "react-toastify";
import { isMobile } from "react-device-detect";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/headers/Footer";
import MenuLists from "./components/headers/MenuLists";
import MainRoutes from "./Routes";
import MobileMenuList from "./components/headers/MobileMenuList";
import { ProviderDataProvider } from "./context/ProviderDataContext";
import { CODDataProvider } from "./context/CODDataContext";
import PushNotificationButton from "./utils/pushNotification/PushNotificationButton";

function App() {
  const isAllowedDomain = window.location.href.startsWith(
    "https://provider.merago.com/"
  );

  return (
    <>
      <CODDataProvider>
        <ProviderDataProvider>
          <ToastContainer autoClose={3000} theme={"colored"} />
          {!isMobile ? <MenuLists /> : <MobileMenuList />}
          {isAllowedDomain && (
            <TawkMessengerReact
              propertyId="629dc2fcb0d10b6f3e75e4ff"
              widgetId="1g4s43bk9"
            />
          )}
          <MainRoutes />
          <PushNotificationButton />
          <Footer />
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </ProviderDataProvider>
      </CODDataProvider>
    </>
  );
}

export default App;
