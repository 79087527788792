import { useEffect, useState } from "react";
import { messaging, getToken } from "./firebase";

const PushNotificationButton = () => {
  const [newToken, setNewToken] = useState('');

  const requestPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_VAPID,
        });
        console.log("FCM Token:", token);
        setNewToken(token); // Update the state with the new token
        // Send this token to your backend to send push notifications
      } else {
        console.error("Permission denied");
      }
    } catch (error) {
      console.error("Error getting permission:", error);
    }
  };

  useEffect(() => {
    requestPermission();
  }, []);

  return <div>{newToken}</div>;
};

export default PushNotificationButton;