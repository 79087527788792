import React, { forwardRef, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { RiArrowDropDownLine, RiCloseFill, RiMenuLine } from "react-icons/ri";

import "./Headers.scss";
import withClickOutSideMobile from "./withClickOutSideMobile";

import { useLogout } from "../../hooks/useLogout";
import { useGetAgentByUserId } from "../../hooks/useAgent";
import { useCODData } from "../../context/CODDataContext";

const AgentMobilemenu = forwardRef(({ open, setOpen }, ref) => {
  const [sideOpen, setSideOpen] = useState(false);
  const { data: agentData } = useGetAgentByUserId(
    localStorage.getItem("agentID") ? localStorage.getItem("agentID") : ""
  );
  const { refetch, data: logoutData } = useLogout();

  const { isAlertAgentWaiting } = useCODData();

  const useAuth = () => {
    const user = localStorage.getItem("agentID");
    if (user) {
      return true;
    } else {
      return false;
    }
  };

  const user = useAuth();
  const location = useLocation();
  const navigation = useNavigate();

  useEffect(() => {
    if (agentData?.status === "SUCCESS") {
      localStorage.setItem("agentFname", agentData?.agentDetails?.firstname);
      localStorage.setItem("agentLname", agentData?.agentDetails?.lastname);
    }
  }, [agentData]);
  const logout = () => {
    refetch();
    if (logoutData?.status === "SUCCESS") {
      localStorage.removeItem("auth");
    }
    localStorage.removeItem("agentID");
    localStorage.removeItem("providerId");
    localStorage.removeItem("clinicId");
    localStorage.removeItem("auth");
    localStorage.removeItem("expires_in");
    localStorage.removeItem("isSCAdmin");
    localStorage.removeItem("refresh_token");
    navigation("/login");
    setOpen(!open);
  };
  const handleMainNavClick = () => {
    setOpen(!open);
  };
  useEffect(() => {
    if (open === false) {
      setSideOpen(false);
    }
  }, [open]);
  const showAlert = isAlertAgentWaiting;

  return (
    <>
      {user && (
        <div className="navbar" ref={ref}>
          <div className="navbar__left">
            <div className="navbar__logo">
              <img
                src="https://static.meragoapp.com/images/menuLogo.png"
                alt="logo"
                width="55px"
                height="55px"
              />
            </div>
          </div>

          <div className="mobile__hamburger" onClick={handleMainNavClick}>
            {!open ? <RiMenuLine /> : <RiCloseFill />}
          </div>
          {open && (
            <div className="mobile__main__menu">
              <div className="main__menu" onClick={() => setOpen(false)}>
                {agentData?.agentDetails?.agentType !== "AGENT_PT" && (
                  <Link
                    to="agent-alert"
                    className={
                      location.pathname.includes("/agent-alert")
                        ? "sidebar_active"
                        : ""
                    }
                  >
                    {isAlertAgentWaiting && (
                      <div className={showAlert ? "red__alert" : ""}></div>
                    )}
                    AlertU Services
                  </Link>
                )}
                <Link
                  to="/agent-dashboard"
                  className={
                    location.pathname.includes("/agent-dashboard")
                      ? "sidebar_active"
                      : ""
                  }
                >
                  {agentData?.agentDetails?.agentType !== "AGENT_PT"
                    ? "Lab Services"
                    : "Home Care"}
                </Link>
                {agentData?.agentDetails?.agentType !== "AGENT_PT" && (
                  <Link
                    to="agent-soboard"
                    className={
                      location.pathname.includes("/agent-soboard")
                        ? "sidebar_active"
                        : ""
                    }
                  >
                    Second Opinion Services
                  </Link>
                )}
                {agentData?.agentDetails?.agentType !== "AGENT_PT" && (
                  <Link
                    to="agent-diagnostic"
                    className={
                      location.pathname.includes("/agent-diagnostic")
                        ? "sidebar_active"
                        : ""
                    }
                  >
                    Diagnostic Services
                  </Link>
                )}
                {agentData?.agentDetails?.agentType !== "AGENT_PT" && (
                  <Link
                    to="agent-redcliff"
                    className={
                      location.pathname.includes("/agent-redcliff")
                        ? "sidebar_active"
                        : ""
                    }
                  >
                    Red Cliff Services
                  </Link>
                )}
              </div>
              <div
                className="mobile__name__side"
                onClick={() => setSideOpen(!sideOpen)}
              >
                <p>
                  {agentData?.agentDetails?.firstname}{" "}
                  {agentData?.agentDetails?.lastname}
                </p>
                <RiArrowDropDownLine />
              </div>
            </div>
          )}
          {sideOpen ? (
            <div className="sidebar__conatiner">
              {location.pathname !== "/login" && (
                <button onClick={logout}>Logout</button>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      {!user && (
        <div className="navbar__login__screen">
          <div className="navbar__logo">
            <img
              src="https://static.meragoapp.com/images/meragoHealth.png"
              alt="logo"
              width="155px"
              height="55px"
            />
          </div>
        </div>
      )}
    </>
  );
});

export default withClickOutSideMobile(AgentMobilemenu);
